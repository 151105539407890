
export default {
	layout: 'shortform',
	data() {
		return {
			sha: process.env.sha
		}
	},
	head() {
		return {
			title: 'Login'
		}
	},
	mounted() {
		// Unset current team if any
		this.$auth.$storage.removeCookie('currentTeam')

		try {
			// this.$route.params is not available when address entered directly
			const signupParam = new URL(location).searchParams.get('signup')

			if (signupParam) {
				setTimeout(() => {
					this.userSignup()
				}, 300)
			} else {
				// redirect to auth0 login on mount, delayed to allow auth0 logout to occur
				setTimeout(() => {
					this.userLogin()
				}, 300)
			}
		} catch (error) {
			this.$logger('Error attempting login page auto redirect', {
				error
			})
		}
	},
	methods: {
		async userLogin() {
			try {
				await this.$auth.loginWith('auth0')
			} catch (err) {
				this.$nuxt.$emit('alert', {
					content: err.response?.data.message,
					type: 'error',
					autodismiss: false,
					isDismissable: false
				})
			}
		},
		async userSignup() {
			try {
				await this.$auth.loginWith('auth0', {
					params: { prompt: 'login', screen_hint: 'signup' }
				})
			} catch (err) {
				this.$nuxt.$emit('alert', {
					content: err.response?.data.message,
					type: 'error',
					autodismiss: false,
					isDismissable: false
				})
			}
		}
	}
}
