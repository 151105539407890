import { render, staticRenderFns } from "./index.vue?vue&type=template&id=47679094&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AlertCenter: require('/opt/render/project/src/apps/nuxt/src/components/Alerts/AlertCenter.vue').default,VStack: require('/opt/render/project/src/apps/nuxt/src/components/Utilities/VStack.vue').default})
